import './tools/svg4everybody';
// import { media } from './tools/MQ';

// Components
import * as carousel from './components/carousel';
import * as file from './components/file';
import * as select from './components/select';
import * as menuMain from './components/menuMain';
import * as matchHeight from './components/matchHeight';
import * as ieCorner from './components/ieCorner';
import * as jobs_filter from './components/jobs-filter';
import * as filter from './components/filter';
import * as form from './components/form';
import * as linkSlide from './components/linkSlide';
import '@justinribeiro/lite-youtube';

import * as cookie from '@superkoders/cookie/src/js/components/cookie';

// content load components
const componentsload = [];

// once delegated components
const components = [carousel, file, select, menuMain, matchHeight, ieCorner, jobs_filter, filter, form, linkSlide].concat(
	componentsload,
);

window.App = {
	run() {
		// console.log(media('lgDown'));
		// console.log(media('webkit'));
		cookie.init();
		const target = document;
		components.forEach((component) => component.init(target));
	},

	initComponent(component) {
		return component.init();
	},
};
