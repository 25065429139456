import EmblaCarousel from 'embla-carousel';

export const init = () => {
	const carousels = Array.from(document.querySelectorAll('.b-carousel'));

	if (carousels) {
		carousels.forEach((carousel) => {
			// buttons
			const setupPrevNextBtns = (prevBtn, nextBtn, embla) => {
				prevBtn.addEventListener('click', embla.scrollPrev, false);
				nextBtn.addEventListener('click', embla.scrollNext, false);
			};
			const disablePrevNextBtns = (prevBtn, nextBtn, embla) => {
				return () => {
					if (embla.canScrollPrev()) prevBtn.removeAttribute('disabled');
					else prevBtn.setAttribute('disabled', 'disabled');

					if (embla.canScrollNext()) nextBtn.removeAttribute('disabled');
					else nextBtn.setAttribute('disabled', 'disabled');
				};
			};

			// dots
			const setupDotBtns = (dotsArray, embla) => {
				dotsArray.forEach((dotNode, i) => {
					dotNode.addEventListener('click', () => embla.scrollTo(i), false);
				});
			};
			const selectDotBtn = (dotsArray, embla) => () => {
				const previous = embla.previousScrollSnap();
				const selected = embla.selectedScrollSnap();
				dotsArray[previous].classList.remove('is-selected');
				dotsArray[selected].classList.add('is-selected');
			};

			// autoplay
			const autoplay = (embla, interval) => {
				let timer = 0;

				const play = () => {
					stop();
					requestAnimationFrame(() => (timer = window.setTimeout(next, interval)));
				};

				const stop = () => {
					window.clearTimeout(timer);
					timer = 0;
				};

				const next = () => {
					if (embla.canScrollNext()) {
						embla.scrollNext();
					} else {
						embla.scrollTo(0);
					}
					play();
				};

				return { play, stop };
			};

			// lazyload
			const lazyLoad = (embla) => {
				const slides = embla.slideNodes();
				const images = slides.map((slide) => slide.querySelector('.b-carousel__img'));
				const imagesInView = [];

				const addImageLoadEvent = (image, callback) => {
					image.addEventListener('load', callback);
					return () => image.removeEventListener('load', callback);
				};

				const loadImagesInView = () => {
					embla
						.slidesInView(true)
						.filter((index) => imagesInView.indexOf(index) === -1)
						.forEach(loadImageInView);
				};

				const loadImageInView = (index) => {
					const image = images[index];
					imagesInView.push(index);
					image.src = image.getAttribute('data-src');

					const removeImageLoadEvent = addImageLoadEvent(image, () => {
						removeImageLoadEvent();
					});
				};

				return () => {
					loadImagesInView();
					return imagesInView.length === images.length;
				};
			};

			// carousel
			const wrap = carousel.querySelector('.b-carousel__carousel');
			const viewPort = wrap.querySelector('.b-carousel__viewport');
			const embla = EmblaCarousel(viewPort, { loop: true });

			const autoplayer = autoplay(embla, 5000);

			embla.on('scroll', () => {
				autoplayer.stop();
			});
			embla.on('settle', () => {
				autoplayer.play();
			});

			const dots = carousel.querySelector('.b-carousel__dots');
			const dotsArray = Array.from(dots.querySelectorAll('.b-carousel__dot-inner'));
			const setSelectedDotBtn = selectDotBtn(dotsArray, embla);

			const prevBtn = wrap.querySelector('.b-carousel__arrow--prev');
			const nextBtn = wrap.querySelector('.b-carousel__arrow--next');
			const disablePrevAndNextBtns = disablePrevNextBtns(prevBtn, nextBtn, embla);

			const loadImagesInView = lazyLoad(embla);
			const loadImagesInViewAndDestroyIfDone = (eventName) => {
				const loadedAll = loadImagesInView();
				if (loadedAll) embla.off(eventName, loadImagesInViewAndDestroyIfDone);
			};

			setupPrevNextBtns(prevBtn, nextBtn, embla);
			setupDotBtns(dotsArray, embla);

			embla.on('select', setSelectedDotBtn);
			embla.on('select', disablePrevAndNextBtns);
			embla.on('init', setSelectedDotBtn);
			embla.on('init', disablePrevAndNextBtns);

			embla.on('init', autoplayer.play);

			embla.on('init', loadImagesInViewAndDestroyIfDone);
			embla.on('select', loadImagesInViewAndDestroyIfDone);
			embla.on('resize', loadImagesInViewAndDestroyIfDone);
		});
	}
};
