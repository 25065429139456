import smoothscroll from 'smoothscroll-polyfill';

export const init = () => {
	const links = Array.from(document.querySelectorAll('.js-link-slide'));
	if (links.length > 0) {
		links.forEach((link) => {
			const id = link.getAttribute('data-href');
			const anchor = document.getElementById(id);

			link.addEventListener('click', function() {
				anchor.scrollIntoView({ behavior: 'smooth' });
			});
		});
	}

	// polyfill
	smoothscroll.polyfill();
};
