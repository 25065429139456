import customSelect from 'custom-select';

export const init = () => {
	const mediaQuery = window.matchMedia('(min-width: 750px)');

	if (mediaQuery.matches) {
		const mySelects = customSelect('select');

		mySelects.forEach((select) => {
			const originalOptions = Array.from(document.querySelectorAll('option', select.select));
			const options = Array.from(document.querySelectorAll('.custom-select-option', select.panel));
			const originalGroups = Array.from(document.querySelectorAll('optgroup', select.select));
			const groups = Array.from(document.querySelectorAll('.custom-select-optgroup', select.panel));

			originalOptions.forEach((node, index) => {
				const classNames = node.className.split(' ').filter(Boolean);
				options[index].classList.add(...classNames);
				options[index].innerHTML = `<span>${options[index].innerHTML}</span>`;
			});

			originalGroups.forEach((node, index) => {
				if (node.dataset.label) {
					const div = document.createElement('div');
					div.className = 'custom-select-optgroup__text';
					div.innerHTML = node.dataset.label || node.label;

					groups[index].prepend(div);
				}

				const classNames = node.className.split(' ').filter(Boolean);
				groups[index].classList.add(...classNames);

				groups[index].dataset.id = node.dataset.id;
			});
		});
	}
};
