import { query } from '@superkoders/sk-tools/src/selector';
import { on } from '@superkoders/sk-tools/src/event';

export const init = (target) => {
	const elements = query('.js-match-height__item', target);
	if (!elements || elements.length === 0) return;

	const update = (isResize) => {
		if (isResize) {
			elements.forEach((element) => {
				element.style.height = '';
			});
		}

		const data = elements
			.map((element) => {
				const { top, height } = element.getBoundingClientRect();
				return {
					top: top,
					height,
					element,
				};
			})
			.reduce((acc, current) => {
				if (acc[current.top] == null) {
					acc[current.top] = [];
				}
				acc[current.top].push(current);
				return acc;
			}, {});

		Object.keys(data).forEach((key) => {
			const group = data[key];
			const heights = group.map(({ height }) => height);
			const maxHeight = Math.max(...heights);
			group.forEach(({ element }) => (element.style.height = `${maxHeight}px`));
		});
	};

	update();

	let resizeTimer = null;
	on(window, 'resize', () => {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(() => {
			update(true);
		}, 100);
	});
};
