import { query } from '@superkoders/sk-tools/src/selector';
import { addClass } from '@superkoders/sk-tools/src/className';
import { append, prepend } from '@superkoders/sk-tools/src/manipulation';

const helper = document.createElement('div');
helper.innerHTML = `
	<svg viewBox="0 0 100 100" preserveAspectRatio="none" class="after">
		<polygon points="20,0 98,75 20,100 0,100 0,0" />
	</svg>
	<svg viewBox="0 0 100 100" preserveAspectRatio="none" class="before">
		<polygon points="80,0 98,75 80,100 0,100 0,0" />
	</svg>
`;

export const init = (target) => {
	if (
		window.CSS?.supports?.(
			'(-webkit-clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%)) or (clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%)',
		)
	)
		return;

	query('.b-filter__link, .btn__text', target).forEach((node) => {
		append(node, query('.after', helper)[0].cloneNode(true));
		addClass(node, 'ie-remove-pseudo-after');
	});
	query('.b-filter__link', target).forEach((node) => {
		prepend(node, query('.before', helper)[0].cloneNode(true));
		addClass(node, 'ie-remove-pseudo-before');
	});
};
