export const init = () => {
	const fileInputs = Array.from(document.querySelectorAll('.js-upload'));
	if (fileInputs.length > 0) {
		fileInputs.forEach((item) => {
			const file = item.querySelector('.js-upload-input');
			const text = item.dataset.text;

			file.addEventListener('change', () => {
				if (file.value.length) {
					if (file.files) {
						const fileName = Object.keys(file.files).map((key) => {
							return file.files[key].name;
						});
						var fileSum = 0;
						for (var i = 0; i < file.files.length; i++) {
							fileSum += file.files[i].size;
						}
						const isBigger = fileSum > 1048576;

						item.setAttribute('data-text', fileName.join(', '));
						item.classList.add('is-set');
						if (isBigger) {
							alert('File is too big!');
							file.value = '';
						}
					} else if (file.value.split('fakepath\\')[1] !== undefined) {
						item.setAttribute('data-text', file.value.split('fakepath\\')[1]);
						item.classList.add('is-set');
					} else {
						item.setAttribute('data-text', file.value);
						item.classList.add('is-set');
					}
				} else {
					item.setAttribute('data-text', text);
					item.classList.remove('is-set');
				}
			});
			file.addEventListener('focus', () => {
				item.classList.add('is-focus');
			});
			file.addEventListener('blur', () => {
				item.classList.remove('is-focus');
			});
		});
	}
};

init(document);
