import $ from 'jquery';

export const init = () => {

	$('input[name="job_filter"]').on('change', function(){
		const $filter = $(this);
		const value = $filter.val();

		if ( value == '' ) {
			window.location = swp.page_jobs + '#jobs';
		} else {
			window.location = swp.page_jobs + '?location=' + value + '#jobs';
		}
	});
};
