export const init = () => {
	const trigger = document.querySelectorAll('.m-main__title');
	var targetMenu = document.querySelectorAll('body');
	let menuOpenStatus = false;

	const menuOpen = function(target) {
		menuOpenStatus = !menuOpenStatus;

		if (menuOpenStatus) {
			target[0].classList.add('menu-open');
		} else {
			target[0].classList.remove('menu-open');
		}
	};

	trigger[0].addEventListener('click', function(event) {
		event.preventDefault();
		menuOpen(targetMenu);
	});
};
