function setButtonText(button, text) {
	button.innerHTML ? button.innerHTML = text : button.value = text;
}

function Loader(formElement) {
	this.form = formElement;
	this.button = formElement.querySelector('input[type="submit"], button[type="submit"]');
}

Loader.prototype.start = function () {
	if (this.button) {
		// loading text
		this.button.setAttribute( 'disabled', 'disabled' );
		var button_text = this.button.querySelector('span');
		var loadingText = this.button.getAttribute('data-loading-text');

		if (loadingText) {
			setButtonText(button_text, loadingText);
			return;
		}
	}
};

Loader.prototype.stop = function () {
	if (this.button) {
		this.button.removeAttribute( 'disabled' );
		var button_text = this.button.querySelector('span');
		var originalText = this.button.getAttribute('data-original-text');
		if (originalText) {
			setButtonText(button_text, originalText);
			return;
		}
	}
};

export const init = () => {

	const form = document.querySelector( '.hf-form' );
	if ( form ) {

		let loader = new Loader( form );
		document.querySelector('.hf-form').addEventListener('hf-submit', function(e) {
			loader.start();
		});
		document.querySelector('.hf-form').addEventListener('hf-submitted', function(e) {
			loader.stop();
		});
	}

};
