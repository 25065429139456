export const init = () => {
	const contacts = Array.from(document.querySelectorAll('.f-contact'));

	contacts.forEach((contact) => {
		const filters = Array.from(contact.querySelectorAll('.f-contact__heading .f-contact__inp'));
		const branches = Array.from(contact.querySelectorAll('.f-contact__content .f-contact__inp'));
		const filterAll = contact.querySelector('.f-contact__heading .f-contact__inp[value="all"]');
		const groups = Array.from(contact.querySelectorAll('.f-contact__group'));
		const select = contact.querySelector('.f-contact__select');
		const boxOther = contact.querySelector('.f-contact__box[data-show="other"]');
		const boxFilter = contact.querySelector('.f-contact__box[data-show="all"]');
		const $recipient = contact.querySelector('#recipient');

		const visibleBoxFilter = function() {
			if (select.value == 'other') {
				boxOther.classList.add('is-visible');
				if (filterAll.checked === true) {
					boxFilter.classList.add('is-visible');
				} else {
					boxFilter.classList.remove('is-visible');
				}
			} else {
				boxOther.classList.remove('is-visible');
				boxFilter.classList.remove('is-visible');
			}
		};

		filters.forEach((filter) => {
			const filterValue = filter.value;
			const filterTitle = filter.dataset.title;

			filter.addEventListener('change', () => {
				groups.forEach((group, index) => {
					const groupId = group.dataset.id;

					if (filterValue != groupId && filterValue != 'all' && groupId != 'other') {
						groups[index].classList.add('is-hidden');
					} else {
						groups[index].classList.remove('is-hidden');
					}
				});
				visibleBoxFilter();

				branches.forEach((branch) => {
					const branchValue = branch.value;
					branch.checked = false;
					if (branchValue == filterTitle) {
						branch.checked = true;
					}
				});

				$recipient.value = swp.emails[filterTitle];
			});

			branches.forEach((branch) => {
				branch.addEventListener('change', (e) => {
					const branchValue = e.target.value;
					if (branchValue) {
						$recipient.value = swp.emails[branchValue];
					}
				});
			});

			select.addEventListener('change', () => {
				visibleBoxFilter();

				const selectValue = select.querySelector('option:checked').parentElement.dataset.name;
				if (selectValue) {
					$recipient.value = swp.emails[selectValue];

					branches.forEach((branch) => {
						const branchValue = branch.value;
						branch.checked = false;
						if (branchValue == selectValue) {
							branch.checked = true;
						}
					});
				}
			});
		});
	});

	if (document.body.classList.contains('page-template-templatestemplate-cv-php')) {
		const recipient = document.getElementById('recipient');
		recipient.value = swp.current_email;
	}
};
